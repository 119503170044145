<template>
  <b-card>
    <b-row>
      <b-button variant="gradient-primary"  v-b-modal.modal-store>
        Cadastrar
      </b-button>
    </b-row>
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
    <b-row>
      
      <b-table-simple
        striped
        stacked="md"
        class="mt-2 responsive text-center"
        id="tableBanner"
        :item="fetchBanner"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <b-thead>
          <b-tr>
            <b-th>Identificação</b-th>
            <b-th>Banner</b-th>
            <b-th>Whatsapp</b-th>
            <b-th>Duração (Segundos) </b-th>
            <b-th>Data de criação</b-th>
            <b-th>Ação</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr :key="indextr" v-for="(tr, indextr) in banners">
            <b-td>{{ tr.id }}</b-td>
            <b-td>
              <b-img :src="tr.url" alt="Responsive image" class="img-responsive" fluid  rounded width="100px" height="150px" />
            </b-td>
            <b-td>{{ tr.whatsapp }}</b-td>
            <b-td>{{ tr.duration }}</b-td>
            <b-td>{{ tr.created_at | dateFormat }}</b-td>
            <b-td>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                v-b-modal.modal-update
                @click="showDetail(tr.id)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                v-b-modal.modal-delete
                @click="showDetail(tr.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableBanner"
        class="mt-1"
      />
      

      <!-- Modal Store -->

      <b-modal
        id="modal-store"
        ok-variant="primary"
        modal-class="modal-primary"
        ref="modal-store"
        centered
        title="Cadastrar"
      >
        <div>
          <b-form enctype="multipart/form-data" method="POST">
            <b-form-input class="hidden" name="_token" :value="csrf" />
            <label for="wildcard">Banner</label>
            <!-- <b-form-file id="image" type="file" placeholder="Por favor insira uma imagem" v-model="dateBanner.url"/> -->
            <b-form-file label="Imagem" type="file" @change="update_image" v-model="url" accept="image/*" name="url"/>
            <label for="wildcard">Whatsapp</label>
            <b-form-input type="text" id="whatsapp" placeholder="Whatsapp" v-model="dateBanner.whatsapp"/>
            <label for="wildcard">Duração em Segundos</label>
            <b-form-input type="number" id="duration" placeholder="Por favor insira a duração do banner em segundos" v-model="dateBanner.duration"/>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="outline-primary"
              block
              @click="storeBanner"
            >
              Cadastrar
            </b-button>
          </b-form>
        </div>
        <template #modal-footer>
          <b-col>
            
          </b-col>
        </template>
      </b-modal>

      <!-- Modal Delete -->

      <b-modal
        id="modal-delete"
        ok-variant="danger"
        modal-class="modal-danger"
        ref="modal-delete"
        centered
        title="Deletar"
      >
        <b-card-text>
          Você está preste a deletar este banner do sistema:
          <hr>
          <b-img :src="bannerModel ? bannerModel.url : ''" alt="Responsive image" rounded height="100px" />
          <hr>
          Por favor, confirme a exclusão antes de prosseguir.
          <b-form-checkbox
            id="checkbox-1"
            @change="newDeleteStatus"
            v-model="DeleteStatus"
            name="checkbox-1"
            value="accepted"
            unchecked-value="null"
          >
            Concordo em excluir este banner
          </b-form-checkbox>
        </b-card-text>
        <template #modal-footer>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="outline-danger"
              block
              :disabled="DeleteStatus == null"
              @click="deleteBanner(bannerModel.id)"
            >
              Deletar
            </b-button>
          </b-col>
        </template>
      </b-modal>

      <!-- Modal update -->

      <b-modal
        id="modal-update"
        ok-variant="warning"
        modal-class="modal-warning"
        ref="modal-update"
        centered
        title="Editar"
      >
        <div>
          <b-form enctype="multipart/form-data" method="POST">
            <b-form-input class="hidden" name="_token" :value="csrf" />
            <label for="wildcard">Banner</label>
            <!-- <b-form-file id="image" type="file" placeholder="Por favor insira uma imagem" v-model="dateBanner.url"/> -->
            <b-form-file label="Imagem" type="file" @change="update_image_update" v-model="url" accept="image/*" name="url"/>
            <label for="wildcard">Whatsapp</label>
            <b-form-input type="text" id="whatsappedit" placeholder="Whatsapp" v-model="updateBanners.whatsapp"/>
            <label for="wildcard">Duração do Banner</label>
            <b-form-input id="name" @change="newDuration" placeholder="Por favor insira a duração do banner em segundos" :value="updateBanners.duration = bannerModel ? bannerModel.duration : ''">
              {{bannerModel ? bannerModel.duration : ''}}
            </b-form-input>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="outline-warning"
              block
              @click="updatebanner(bannerModel.id)"
            >
              Editar
            </b-button>
          </b-form>
        </div>
        <template #modal-footer>
          <b-col>
            
          </b-col>
        </template>
      </b-modal>

    </b-row>
    </b-overlay>
  </b-card>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      bannerModel: null,
      DeleteStatus: null,
      url: null,

      dateBanner: {
        url: null,
        duration: null,
        whatsapp: null,
      },

      updateBanners: {
        url: null,
        duration: null,
        whatsapp: null,
      },

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
      
    };
  },
  computed: {
    ...mapState("banners", ["banners", "pagination"]),
  },
  methods: {
    ...mapActions("banners", ["store", "update"]),
    storeBanner() {
      this.store({
        ...this.dateBanner,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchBanner();
        this.$refs['modal-store'].hide()
      });
    },
    updatebanner(id) { 
      let data = {
        id,
        ...this.updateBanners,
      };
      this.$store.dispatch("banners/update", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
        this.updateBanners.url = null
        this.updateBanners.duration = null
        this.updateBanners.whatsapp = null
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchBanner();
        this.$refs['modal-update'].hide()
      });
    },
    fetchBanner(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("banners/fetchBanner", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteBanner(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("banners/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchBanner();
        this.$refs['modal-delete'].hide()
      });
    },
    update_image(e) {
      var files = e.target.files;
      this.dateBanner.url =  files[0]
    },
    update_image_update(e) {
      var files = e.target.files;
      this.updateBanners.url =  files[0]
    },
    newDuration(value){
      this.updateBanners.duration = value;
    },
    showDetail(id){
      this.bannerModel = this.$store.getters['banners/getBannersById'](id)
    },
    handlePageChange(value) {
      this.fetchBanner(true, value);
    },
    newDeleteStatus(value){
      this.DeleteStatus = value
    },
  },
  created() {
    this.fetchBanner();
    if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  watch: {

  },
};
</script>